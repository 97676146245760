export const VALIDATIONS = {
    NAME: 32,
    PHONE_MIN: 10,
    PHONE_MAX: 16,
    BIO_MAX: 255,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 20,
    VERIFICATION_CODE: 4,
    CARD_NUMBER: 16,
    CVV: 3,
    ADDRESS_MAX: 200,
    STATE: 20,
    PASS:20,
    CPASS:20,
    PHONEMAX:16,

}

export const VALIDATIONS_TEXT = {
    NAME_REQUIRED: "Full name is required",
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
    EMAIL_REQUIRED: "Email is required",
    EMAIL_FORMAT: "Enter valid email address",
    EXCLUDE_SPECIAL_CHARACTERS: "Enter full name containing alphabets only",
    PASSWORD_FORMAT: "Enter password containing atleast one uppercase",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PHONE_REQUIRED: "Contact number is required",
    ADDRESS_REQUIRED: "Address is required",
    STATE_REQUIRED: "State is required",
    CARD_NUMBER_REQUIRED: "Card number is required",
    INVALID_STATE_FORMAT: "Enter state containing alphabets only",
    INVALID_FORMAT_FIRST_NAME: "Enter first name containing alphabets only",
    INVALID_FORMAT_LAST_NAME: "Enter last name containing alphabets only",
    
    NAME_MAX: "Max length is " + VALIDATIONS.NAME,
 
    STATE_MAX: "Max length is " + VALIDATIONS.STATE,
    EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
    PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,
    CARD_NUMBER_MIN: "Min length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Max length is " + VALIDATIONS.CARD_NUMBER,
    PHONE_MIN: "Min length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Max length is " + VALIDATIONS.PHONE_MAX,
    BIO_MAX: "Max length is " + VALIDATIONS.BIO_MAX,
    CVV_MIN: "Min length is " + VALIDATIONS.CVV,
    CVV_MAX: "Max length is " + VALIDATIONS.CVV,
    ADDRESS_MAX: "Max length is " + VALIDATIONS.ADDRESS_MAX,
    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",
    INVALID_PHONE_FORMAT: "Enter correct number containing only 16 digits"
}

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]


export const EXCLUDE_NUMBER_INPUT = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
]

export const EXCLUDE_ALPHABETS_SPECIAL_CHAR = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",  "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]
