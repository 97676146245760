import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const DeleteAlert = (props) => {
  return (
    <>
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <section className="delete-popup">
        <Container>
          <Row>
            <Col>
              <h1>Delete Alert</h1>
              <hr className="bottom-border" />
              <p>Are you sure you want to delete?</p>
              <div className="btn-div">
                <Button className="yes-btn" onClick={props.onHide}>Yes</Button>
                <Button onClick={props.onHide} className="no-btn">No</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Modal>


    </>
  );
};

export default DeleteAlert;
